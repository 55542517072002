import {defineStore} from "pinia/dist/pinia";
import User from "../models/User";
import AuthService from "../api/AuthService";
import AccountService from "../api/AccountService.js";
import {i18n} from "../i18n.js";
import * as Sentry from "@sentry/browser";

export const useAuthStore = defineStore('auth', {
    state: () => (
        {
            authUser: null,
            loggingOut: false,
            unreadAnnouncements: null,
            skippedAnnouncements: null
        }
    ),
    getters: {},
    actions: {
        async attemptLogin(data) {
            this.loggingOut = false;

            const response = await AuthService.postLogin(data);

            this.authUser = new User(response.data.data);

            this.setPopups();

            i18n.locale = this.authUser.locale;

            try {
                Sentry.setUser({
                    id: this.authUser.id
                })
            } catch (e) {

            }

            return response.data.data.id;
        },
        async getMe() {
            const response = await AuthService.getMe();

            this.authUser = new User(response.data.data);

            // The below could be moved to the backend as a part of a refactor
            // But the idea here is to only show one pop up per call to /me
            this.setPopups();

            this.unreadAnnouncements = response.data.unread_announcements;
            this.skippedAnnouncements = response.data.skipped_announcements;

            i18n.locale = this.authUser.locale;

            try {
                Sentry.setUser({
                    id: this.authUser.id
                })
            } catch (e) {

            }
        },
        async updateMe(data) {
            const response = await AuthService.updateMe(this.authUser.id, data);

            this.authUser = new User(response.data.data);

            i18n.locale = this.authUser.locale;
        },
        async forgotPassword(data) {
            return await AuthService.postForgotPassword(data);
        },
        async resetPassword(data) {
            return await AuthService.postResetPassword(data);
        },
        async switchTeam(teamId) {
            const response = await AccountService.postSwitchTeam(teamId);

            return await this.getMe();
        },
        async logout() {
            this.loggingOut = true;

            await AuthService.postLogout();

            this.authUser = null;

            try {
                Sentry.setUser(null)
            } catch (e) {

            }
        },
        removeUnreadAnnouncement(id) {
            this.unreadAnnouncements = this.unreadAnnouncements.filter(announcement => announcement !== id);
        },
        removeUnreadAnnouncementAndMarkSkipped(id) {
            this.skippedAnnouncements.push(id);
        },
        setPopups() {
            if (this.authUser.should_see_welcome_popup) {

                this.authUser.should_see_nps_popup = false;
                this.authUser.daily_question = false;

                if (this.authUser.should_see_team_lead_popup) {
                    this.authUser.should_see_team_lead_popup = false;

                    this.authUser.nextPopup = {
                        type: 'team_lead',
                    }
                }
            }

            if (this.authUser.daily_question) {
                this.authUser.should_see_nps_popup = false;
            }
        }
    }
});
